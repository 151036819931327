import React, { Component } from "react";
import { Layout, Menu } from "antd";
import Link from "next/link";
import { Auth } from "aws-amplify";
const { Header } = Layout;

export default class Base extends Component<any, any> {
    constructor(props: any) {
        super(props);

        this.onMenuClick = this.onMenuClick.bind(this);
    }

    onMenuClick(event: any): void {
        const key = event.key;
        if (key === "home") {
            window.location.assign("/");
        } else if (key === "overview") {
            window.location.assign("/overview");
        } else if (key === "updates") {
            window.location.assign("/updateoverview");
        } else if (key === "profile") {
            window.location.assign("/profile");
        } else if (key === "signout") {
            Auth.signOut();
        }
    }

    render(): JSX.Element {
        return (
            <Header>
                <Link href="/">
                    <div className="logo">
                        <img src="/images/arm-48-48.png" />
                    </div>
                </Link>
                <Menu
                    theme="dark"
                    mode="horizontal"
                    items={[
                        {
                            label: "Home",
                            key: "home"
                        },
                        {
                            label: "Overview",
                            key: "overview"
                        },
                        {
                            label: "Updates",
                            key: "updates"
                        },
                        {
                            label: "Profile",
                            key: "profile"
                        },
                        {
                            label: "Sign-Out",
                            key: "signout"
                        }
                    ]}
                    onClick={this.onMenuClick}
                />
            </Header>
        );
    }
}
