import { Workbox } from "workbox-window";

export enum ServiceWorkerEventType {
    // eslint-disable-next-line no-unused-vars
    NEW_VERSION,
}

export default class ServiceWorker {
    // Bijhouden van een lijst met listeners
    static listeners: any = {};

    static register(): void {
        // Registeren van this
        this.onWaitingEvent = this.onWaitingEvent.bind(this);
        this.install = this.install.bind(this);

        // Controleer of er een ServiceWorker is
        if (
            typeof window !== "undefined" &&
            "serviceWorker" in navigator &&
            (window as any).workbox !== undefined
        ) {
            // Ophalen van de serviceworker
            const wb: Workbox = (window as any).workbox;

            // Maken van een registratie wanneer er een nieuwe versie beschikbaar is
            wb.addEventListener("waiting", this.onWaitingEvent);
            // wb.addEventListener('externalwaiting', this.onWaitingEvent);

            // Registeren van de ServiceWorker
            wb.register();
        }
    }

    static addEventListener(
        eventType: ServiceWorkerEventType,
        callBack: any
    ): void {
        // Check of het type al bestaat
        if (!(ServiceWorkerEventType.NEW_VERSION in this.listeners)) {
            // Toevoegen van het type
            this.listeners[ServiceWorkerEventType.NEW_VERSION] = [];
        }

        // Toevoegen van de listener
        this.listeners[ServiceWorkerEventType.NEW_VERSION].push(callBack);
    }

    static removeEventListener(
        eventType: ServiceWorkerEventType,
        callBack: any
    ): void {
        // Check of het type bestaat
        if (ServiceWorkerEventType.NEW_VERSION in this.listeners) {
            // Check of de listener bestaat
            if (
                callBack in this.listeners[ServiceWorkerEventType.NEW_VERSION]
            ) {
                // Verwijderen van de listener
                this.listeners[ServiceWorkerEventType.NEW_VERSION].remove(
                    callBack
                );
            }
        }
    }

    static onWaitingEvent(event: any): void {
        if (ServiceWorkerEventType.NEW_VERSION in this.listeners) {
            this.listeners[ServiceWorkerEventType.NEW_VERSION].forEach(
                (listener: Function) => {
                    listener(event);
                }
            );
        }
    }

    static install(event: any): void {
        // Ophalen van de serviceworker
        const wb: Workbox = (window as any).workbox;

        // Toevoegen van een tijdelijk listener voor het opnieuw opstarten
        event.sw.addEventListener("controlling", () => {
            window.location.reload();
        });

        // Doorgeven dat we mogen installeren
        wb.messageSW({ type: "SKIP_WAITING" });

        // Set a timeout to Upgrader after 5 seconds
        setTimeout(() => {
            window.location.reload();
        }, 5000);
    }
}
