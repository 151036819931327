import React from "react";

// Connect with Amplify
import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

// Onderdelen
import Head from "next/head";
import { __APP_NAME__, __ENVIRONMENT__, __VERSION__ } from "../settings";
import { Layout } from "antd";
import "../styles/main.scss";
import Base from "../components/base";
import InstallController from "../modules/install";
import ServiceWorker from "../modules/serviceworker";
Amplify.configure({
    Auth: {
        region: "eu-central-1",
        userPoolId: "eu-central-1_r3w1zIWBB",
        userPoolWebClientId: "7japutfjvugk2nmktatud9u71m"
    }
});
const { Content, Footer } = Layout;

export default class App extends React.Component<any, any> {
    componentDidMount(): void {
        // Zorgen dat de InstallController ready is
        InstallController.initialize();

        // Registeren van de Service Worker
        if (__ENVIRONMENT__ !== "development") {
            ServiceWorker.register();
        }
    }

    render(): JSX.Element {
        const { Component, props } = this.props;
        return (
            <>
                <Head>
                    <title>{__APP_NAME__}</title>
                </Head>
                <Authenticator hideSignUp={true}>
                    <Layout className="layout">
                        <Base />
                        <Content className="maincontent">
                            <Component {...props} />
                        </Content>
                        <Footer style={{ textAlign: "center" }}>
                            Alexion Software © 2023 - Version Management -{" "}
                            {__VERSION__}
                        </Footer>
                    </Layout>
                </Authenticator>
            </>
        );
    }
}
