/** Class voor wanneer we installatie klaar hebben staan */
export class InstallReadyEvent extends Event {
    /** Bijhouden van het naam van het event */
    static EVENT_NAME: string = "AT_INSTALL_PROMPT_READY";

    /** Constructor */
    constructor() {
        super(InstallReadyEvent.EVENT_NAME);
    }
}
