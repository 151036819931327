import { InstallReadyEvent } from "./events";

/**
 * Class waarmee we de PWA kunnen installeren
 * @class
 */
export default class InstallController {
    private static BEFORE_INSTALL_EVENT: any = null;

    /**
     * Opbouwen van de InstallController
     */
    static initialize(): void {
        // Koppel de beforeinstallprompt
        window.addEventListener(
            "beforeinstallprompt",
            InstallController.onBeforeInstall
        );
    }

    /**
     * Ophalen of we de PWA kunnen installeren
     * @returns {boolean} True == We kunnen installeren, False = We kunnen niet installeren
     */
    static canInstall(): boolean {
        // Check of we een keer op dismiss hebben geclicked
        if (localStorage.getItem("AT_INSTALL_DISMISS") !== null) {
            // We willen dit niet
            return false;
        }

        // Check of we een event hebben
        if (this.BEFORE_INSTALL_EVENT !== null) {
            // We mogen installeren
            return true;
        }
        return false;
    }

    /**
     * Wanneer de browser de installatie wil laten zien
     * @param event {BeforeInstallEvent}
     */
    static onBeforeInstall(event: Event): void {
        // Sla het event op
        InstallController.BEFORE_INSTALL_EVENT = event;

        // Vertellen aan de rest van de wereld
        const installEvent: InstallReadyEvent = new InstallReadyEvent();
        window.dispatchEvent(installEvent);

        // Zorgen dat de popup niet wordt getoond
        event.preventDefault();
    }

    /**
     * Laten zien van de installatie prompt van de browser
     */
    static install(): Promise<void> {
        return new Promise((resolve, reject) => {
            // Laten zien van de installatie
            this.BEFORE_INSTALL_EVENT.prompt();

            // Wachten tot we antwoord hebben van de gebruiker
            this.BEFORE_INSTALL_EVENT.userChoice
                .then(() => {
                    // Doorgeven dat het is gelukt
                    resolve();
                })
                .catch((err: any) => {
                    // Er is een probleem
                    reject(err);
                })
                .finally(() => {
                    // Wissen van het event want deze is gebruikt
                    this.BEFORE_INSTALL_EVENT = null;
                });
        });
    }

    /**
     * Niet laten zien van de installatie banner
     */
    static dismiss(): void {
        // Opslaan van de dismiss
        localStorage.setItem("AT_INSTALL_DISMISS", "1");
    }
}
